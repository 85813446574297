<template>

<div >
  <van-nav-bar
      title="方安物业缴费系统"
      :left-arrow="isarrow"


  />
  <h1></h1>



<div>
  <van-cell-group inset>

    <van-field
        value="淄博方安物业"
        label="缴费机构" left-icon="shop-o" readonly
    />
    <van-field
        value="方达梦想小镇"
        label="分组" left-icon="setting-o" readonly
    />
    <van-field
        v-model="doorno"
        placeholder="请输入缴纳费用户号"
        label="户号" left-icon="contact"
    />

  </van-cell-group>
  <h2 class="van-doc-demo-block__title"></h2>

  <h2 class="van-doc-demo-block__title"></h2>
  <h2 class="van-doc-demo-block__title"></h2>
  <van-cell-group inset class="jaofei">
  <van-button type="primary" @click="querygym" size="large">查询</van-button>
  </van-cell-group>
</div>
  <div class="van-contact-list__bottom">

  </div>
</div>

</template>

<script>
import {Button, Cell, CellGroup, List, NavBar, ContactList, Tag, Form, Field, Dialog} from 'vant';
import {getUrlCode, getOpenid} from '@/api/wxmppay'
//import {getUnifiedorderResult} from "@/api/wxmppay"
//import wx from 'weixin-js-sdk'
import {  setOpenid,removeOpenid } from '@/utils/auth'



export default {
  name: "querybill",

  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]:  CellGroup,
    [List.name]:List,
    [NavBar.name]:NavBar,
    [ContactList.name]:ContactList,
    [Tag.name]:Tag,
    [Form.name]: Form,
    [Field.name]:Field,
    [Dialog.name]: Dialog

  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isarrow: false,
      code:'',
      query:{
        code: "",
      },
      data:{
        openid:""
      },
      doorno:""
    };
  },
  created() {
    //Dialog.alert(this.$route.query.code);
    this.onLoad()
  },
  methods: {
    onLoad() {
      //Dialog.alert(this.$route.query.code);
      console.log(this.$route.query.code==undefined)
      if(this.$route.query.code==undefined){

       getUrlCode().then(res => {
         console.log(res)
         //window.open(res,"_blank");
          location.href =res
       })
     }else{
      this.code=this.$route.query.code;
      this.query.code=this.code
      getOpenid(this.query).then(res=>{
        this.data.openid=res.Openid
        this.$store.commit("SET_OPENID",res.Openid)
        removeOpenid()
        setOpenid(res.Openid)
        console.log(this.$store.state.openid)
        //获取支付信息
         //getUnifiedorderResult(this.data).then(res=>{
        //   function onBridgeReady(){
        //     WeixinJSBridge.invoke(
        //         'getBrandWCPayRequest', {
        //           "appId":res.AppId,     //公众号ID，由商户传入
        //           "timeStamp":res.TimeStamp,         //时间戳，自1970年以来的秒数
        //           "nonceStr":res.NonceStr, //随机串
        //           "package":res.Package,
        //           "signType":"SIGN_TYPE_MD5",         //微信签名方式：
        //           "paySign":res.PaySign //微信签名
        //         },
        //         function(res){
        //           if(res.err_msg == "get_brand_wcpay_request:ok" ){
        //             // 使用以上方式判断前端返回,微信团队郑重提示：
        //             //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        //           }
        //         });
        //   }
        //   if (typeof WeixinJSBridge == "undefined"){
        //     if( document.addEventListener ){
        //       document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        //     }else if (document.attachEvent){
        //       document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
        //       document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        //     }
        //   }else{
        //     onBridgeReady();
        //   }

      //  })
      })
     }
    //获取openid并保存
      //getOpenid().then{}
    },
    querygym(){
      if(this.doorno==""){
        Dialog.alert({
          message: '没有填写户号',
        }).then(() => {
          // on close
          return ;
        });
        return ;
      }
      this.$router.push({
        path: '/pay',
        query: {doorno: this.doorno}

      })
    }
  },
}

</script>

<style scoped>
.paybg{background: #1677ff;}
.jiaofei{margin-top: 15px;}
</style>